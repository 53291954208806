'use client';

import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import NextError from 'next/error';
import { DEFAULT_LOCALE_LOWERCASE } from 'src/utils/localization';

// This file get hit when there are errors fired in the global layout itself,
// i.e. app/[locale]/layout.js
// As such, this shouldn't try to use all of the same components/contexts as
// the global layout, since those components are likely to error here as well.
//
// Docs: https://nextjs.org/docs/app/api-reference/file-conventions/error#global-errorjs

const GlobalError = ({ error }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang={DEFAULT_LOCALE_LOWERCASE}>
      <body>
        <NextError />
      </body>
    </html>
  );
};

export default GlobalError;
